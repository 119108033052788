"use client";

import { faArrowRightFromBracket } from "@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket";
import { faFileArrowUp } from "@fortawesome/pro-solid-svg-icons/faFileArrowUp";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons/faLocationDot";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Image, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

import { AccountModal } from "@/components/account-modal";
import CsvModal from "@/components/csvModal/csvModal";
import { useUserContext } from "@/context/user.context";
import { closeExtension } from "@/utils/extension";

const SHARED_BUTTON_PROPS = {
  color: "#784DD6",
  className: "uppercase",
  fw: 600,
  pl: 6,
  size: "xs",
  variant: "transparent",
} as const;

interface HeaderProps {
  hideAccountButtons?: boolean;
}

export const Header = ({ hideAccountButtons = false }: HeaderProps) => {
  const { logOut, user } = useUserContext();
  const [
    accountModalOpened,
    { close: closeAccountModal, open: openAccountModal },
  ] = useDisclosure(false);
  const [csvModalOpened, { open: openCsvModal, close: closeCsvModal }] =
    useDisclosure(false);

  return (
    <header
      className={twMerge([
        "bg-white",
        "border-b-2",
        "border-gray-2000",
        "flex",
        "items-center",
        "justify-between",
        "px-[18px]",
        "py-4",
        "w-full",
        "z-40",
      ])}
    >
      <div className="flex gap-6 items-center">
        <Link href="/">
          <Image src="/assets/logo.svg" alt="Logo" h={20} />
        </Link>
        <Text size="sm" fw={600}>
          {user?.company.name || ""}
        </Text>
      </div>
      <Flex align="center">
        {user && (
          <>
            {user.internal && (
              <Button
                {...SHARED_BUTTON_PROPS}
                onClick={() => openCsvModal()}
                rightSection={<FontAwesomeIcon icon={faFileArrowUp} />}
              >
                Upload CSV
              </Button>
            )}
            {!hideAccountButtons && (
              <>
                <Button
                  {...SHARED_BUTTON_PROPS}
                  component={Link}
                  href="/assigned-to/me"
                  rightSection={<FontAwesomeIcon icon={faLocationDot} />}
                >
                  My Locations
                </Button>
                <Button
                  {...SHARED_BUTTON_PROPS}
                  onClick={() => openAccountModal()}
                  rightSection={<FontAwesomeIcon icon={faUser} />}
                >
                  Account
                </Button>
                <Button
                  {...SHARED_BUTTON_PROPS}
                  component={Link}
                  href="/auth/login?logout=true"
                  onClick={() => logOut()}
                  rightSection={
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  }
                >
                  Log out
                </Button>
              </>
            )}
          </>
        )}
        <ActionIcon
          variant="transparent"
          onClick={() => closeExtension()}
          aria-label="close"
        >
          <Image src="/assets/circle-x.svg" alt="Logo" h={20} />
        </ActionIcon>
      </Flex>
      <AccountModal onClose={closeAccountModal} opened={accountModalOpened} />
      <CsvModal opened={csvModalOpened} onClose={closeCsvModal} />
    </header>
  );
};
